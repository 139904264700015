<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="isLoading"
    item-key="id"
    loader-height="1"
    :items-per-page="50"
    :footer-props="{
      itemsPerPageOptions: [25, 50, 75, 100, -1]
    }"
    @click:row="$emit('click:row', $event)"
  >
    <template v-slot:item.attributes.name="{ item }">
      {{ item.attributes.name | titleCase }}
    </template>

    <template v-slot:item.customName="{ item }">
      {{ item.attributes.customAttributes.customName | titleCase }}
    </template>

    <template v-slot:item.specie="{ item }">
      {{ $t(`species.${item.attributes.customAttributes.specie.text}`) }}
    </template>

    <template v-slot:item.dimensions="{ item }">
      <ul>
        <li v-if="item.attributes.dimensions.length">
          <span class="text-caption grey--text"> L</span>
          {{
            toMeters(
              item.attributes.dimensions.length,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          m
        </li>
        <li v-if="item.attributes.dimensions.width">
          <span class="text-caption grey--text"> W </span>
          {{
            toCentimeters(
              item.attributes.dimensions.width,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          cm
        </li>
        <li v-if="item.attributes.dimensions.height">
          <span class="text-caption grey--text"> T </span>
          {{
            toMillimeters(
              item.attributes.dimensions.height,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          mm
        </li>
        <li v-if="item.attributes.dimensions.diameter">
          <span class="grey--text"> ⌀ </span>
          {{
            toCentimeters(
              item.attributes.dimensions.diameter,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          cm
        </li>
        <li v-if="item.attributes.volume">
          <span class="grey--text"> Vol. </span>
          {{ item.attributes.volume || '·' }}
          <span
            v-if="item.attributes.volume && item.attributes.volumeUnit !== 'm³'"
            >{{ item.attributes.volumeUnit }}</span
          >
        </li>
      </ul>
    </template>
    <template v-slot:item.quantity="{ item }">
      <div v-if="item.attributes.status === 'in_stock'">
        {{ item.attributes.quantity }}
      </div>
      <v-chip v-else small outlined color=" 'error'">{{
        item.attributes.status | titleCase
      }}</v-chip>
    </template>
    <!-- <template v-slot:item.itemGroup="{ item }">
      <div
        v-for="name in getItemGroup(item.relationships.itemGroup.data)"
        :key="name"
      >
        {{ name }}
      </div>
    </template> -->
    <!-- <template v-slot:item.log="{ item }">
      {{ getItemResource(item.attributes.customAttributes.resource) || '-' }}
    </template> -->
    <template v-slot:item.incomingShipment="{ item }">
      <v-btn
        v-if="item.attributes.customAttributes.incomingShipment"
        depressed
        x-small
        :to="{
          name: 'purchase-order-show',
          params: {
            orderId: item.attributes.customAttributes.incomingShipment
          }
        }"
        color="blue lighten-4"
        class="text-capitalize"
        >{{ $t('actions.view') }}</v-btn
      >
    </template>

    <template v-slot:item.cost="{ item }">
      {{ item.attributes.costPriceDetails.amount || '·' }}
      <span v-if="item.attributes.costPriceDetails.amount">{{
        item.attributes.costPriceDetails.currency
      }}</span>
    </template>

    <template v-slot:item.id="{ item }">
      <!-- <v-btn
        icon
        small
        color="info"
        @click="
          $router.push({
            name: 'inventory-item-show',
            params: { inventoryItemId: item.id }
          })
        "
        ><v-icon small>mdi-eye</v-icon></v-btn
      > -->
      <BaseTableActions
        module-name="inventoryItem"
        :item="item"
        :to-show="{
          name: 'inventory-item-show',
          params: { inventoryItemId: item.id }
        }"
        :to-edit="{
          name: 'inventory-item-edit',
          params: {
            inventoryItemId: item.id
          }
        }"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
import BaseTableActions from '../../../components/buttons/BaseTableActions.vue'

export default {
  components: { BaseTableActions },
  mixins: [UnitsConversion],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: [],
      isLoading: true,
      itemGroupLots: [],
      headers: [
        {
          text: this.$t('common.name'),
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$t('item.description'),
          align: 'start',
          sortable: false,
          value: 'attributes.description'
        },
        {
          text: this.$t('item.specie'),
          value: 'specie',
          sortable: false
        },
        {
          text: this.$t('item.dimensions'),
          sortable: false,
          align: 'end',
          value: 'dimensions'
        },

        {
          text: this.$t('item.quantity'),
          sortable: false,
          value: 'attributes.itemUnitsCount'
        },
        {
          text: this.$t('purchase.shortName'),
          sortable: false,
          value: 'incomingShipment'
        },
        {
          text: '',
          sortable: false,
          value: 'id',
          align: 'end'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      inventoryItems: 'inventoryItem/items',
      facilities: 'facility/facilities',
      itemResources: 'itemResource/itemResources'
    })
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      // await this.filterInventoryItem()
      this.itemGroupLots = await this.fetchitemGroupLots()
      await this.fetchFacilities()
      // await this.fetchItemResources()
      this.isLoading = false
    },
    async filterInventoryItem() {
      await this.$store.dispatch('inventoryItem/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    // async fetchItemResources() {
    //   return await this.$store.dispatch('itemResource/filter', {
    //     organizationId: ORGANIZATION_ID
    //   })
    // },
    async fetchitemGroupLots() {
      return await this.$store.dispatch('itemGroupLot/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    getFacility(facilityId) {
      if (!facilityId || !this.facilities.length) return
      const {
        attributes: { name }
      } = this.facilities.find(facility => facility.id === facilityId)
      return name
    },
    getItemGroup(data) {
      if (!data || !this.itemGroupLots.length) return
      const itemGroup = this.itemGroupLots.find(
        itemGroup => itemGroup.id === data.id
      )
      if (!itemGroup) return

      const { description } = itemGroup.attributes
      const facilityId = itemGroup.relationships.facility.data?.id
      const name = this.getFacility(facilityId)
      return { description, name }
    }
    // getItemResource(id) {
    //   if (!id || !this.itemResources.length) return
    //   const response = this.itemResources.find(
    //     itemResource => itemResource.id === id
    //   )
    //   if (response) return response.attributes.description
    // }
  }
}
</script>
<style lang="scss" scoped></style>
