<template>
  <div v-if="itemGroupLot">
    <BaseLayoutTitle
      v-if="itemGroupLot.data"
      :prefix="$tc('lot.name', 1)"
      :title="itemGroupLot.data.attributes.description"
    >
      <template v-slot:edit>
        <v-btn
          v-if="isAdmin"
          tile
          outlined
          color="primary"
          class="text-capitalize mr-2"
          :to="{ path: 'edit' }"
          append
          ><v-icon left>mdi-clipboard-edit-outline</v-icon
          >{{ $t('actions.edit') }}</v-btn
        ></template
      >
    </BaseLayoutTitle>
    <ItemGroupLotShowItemTable
      v-if="itemGroupLot.included"
      :items="itemGroupLot.included"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemGroupLotShowItemTable from '@/views/item-group-lot/components/ItemGroupLotShowItemTable'
export default {
  components: {
    ItemGroupLotShowItemTable
  },
  props: {
    itemGroupLotId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      itemGroupLot: 'itemGroupLot/itemGroupLot',
      isAdmin: 'auth/isAdmin'
    })
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.fetchItemGroupLot(this.itemGroupLotId)
    },
    async fetchItemGroupLot(id) {
      return await this.$store.dispatch('itemGroupLot/fetch', id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
